<template>
  <div
    :class="`script-outer-container ${dialog ? 'dialog-show' : ''}`"
  >
    <div
      ref="scriptContainer"
      class="script-container"
    >
      <div ref="script" />
    </div>
    <div
      v-show="dialog"
      class="offer-container"
    >
      <v-card flat>
        <v-card-text class="px-10">
          <validation-observer
            ref="form"
            v-slot="{ valid }"
            tag="form"
            @submit.prevent="sendOffer"
          >
            <v-card-title class="headline">
              Submit the form below to send the offer to your email.
            </v-card-title>
            <v-card-text>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Name"
              >
                <v-text-field
                  v-model="form.name"
                  name="name"
                  label="Name"
                  type="text"
                  prepend-icon="mdi-account"
                  :error-messages="errors"
                  required
                  @focus="showKeyboard"
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                rules="required|email"
                name="Email"
              >
                <v-text-field
                  v-model="form.email"
                  name="email"
                  label="Email Address"
                  type="text"
                  prepend-icon="mdi-email"
                  :error-messages="errors"
                  required
                  @focus="showKeyboard"
                />
              </validation-provider>
              <v-checkbox v-model="form.subscribe" label="Would you like to receive emails for similar offers?" />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                type="submit"
                color="blue"
                :loading="formLoading"
                :disabled="!valid || formLoading"
              >
                Proceed
              </v-btn>
              <v-spacer />
            </v-card-actions>
          </validation-observer>
        </v-card-text>
        <v-card-text
          v-if="offerDataUrl"
          class="d-flex justify-center px-10"
        >
          <v-card
            outlined
            raised
          >
            <v-card-title class="headline justify-center">
              Offer Details
            </v-card-title>
            <v-divider />
            <v-img :src="offerDataUrl" />
          </v-card>
        </v-card-text>
      </v-card>
      <v-dialog
        v-model="successDialog"
        max-width="600px"
      >
        <v-card>
          <v-alert
            type="success"
            class="mb-0"
            outlined
            prominent
          >
            <h3 class="title font-weight-bold">
              Email successfully sent!
            </h3>
            <v-divider
              class="my-2 success"
              style="opacity: 0.22"
            />
            <div class="mb-2">
              Would you like to close the Konfigurator or continue where you left off?
            </div>
            <v-row
              justify="space-around"
            >
              <v-btn
                color="info"
                outlined
                @click="$emit('close')"
              >
                Close
              </v-btn>
              <v-btn
                color="info"
                outlined
                @click="back"
              >
                Continue
              </v-btn>
            </v-row>
          </v-alert>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="visible"
        hide-overlay
        persistent
        max-width="1700px"
      >
        <v-card color="grey">
          <v-card-title>
            <v-spacer />
            <v-btn
              fab
              text
              @click="visible = false"
            >
              <v-icon color="white" v-text="'mdi-close'" />
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-3">
            <vue-touch-keyboard
              v-if="visible"
              :options="options"
              :layout="layout"
              :cancel="hideKeyboard"
              :accept="acceptKeyboard"
              :input="input"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import VueTouchKeyboard from 'vue-touch-keyboard'
import 'vue-touch-keyboard/dist/vue-touch-keyboard.css' // load default style
// import { auth, storageRef } from '../../../plugins/firebase'
import axios from 'axios'
export default {
  name: 'MScript',
  components: {
    'vue-touch-keyboard': VueTouchKeyboard.component
  },
  props: {
    src: {
      type: String,
      default: () => ''
    },
    layoutId: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    onLastPage: undefined,
    onLastPageCheck: undefined,
    dialog: false,
    canvas: undefined,
    offerDataUrl: undefined,
    formLoading: false,
    form: {
      name: '',
      email: '',
      subscribe: false
    },
    successDialog: false,
    visible: false,
    layout: 'normal',
    input: null,
    options: {
      useKbEvents: false,
      preventClickEvent: false
    }
  }),
  mounted() {
    const js = document.createElement('script')
    js.id = this.src
    js.src = this.src
    this.$refs.script.replaceWith(js)
    setTimeout(() => {
      this.$emit('loaded')
    }, 1000)

    // track konfigurator status on testing layout
    if (this.layoutId === '9zzqPwknBz9tASBukevu') {
      this.$refs.scriptContainer.addEventListener('click', this.clickOnScript)
      this.onLastPageCheck = setInterval(() => {
        const nav = this.$refs.scriptContainer.getElementsByClassName('nav-main__list')[0]
        this.onLastPage = nav && nav.querySelector('li:last-child').classList.contains('is-active')
      }, 1000)
    }
  },
  beforeDestroy() {
    clearInterval(this.onLastPageCheck)
  },
  methods: {
    clickOnScript(event) {
      // if clicking on Weiter Zur Anfrage
      if (this.onLastPage && event.target.parentNode.classList.contains('summary__send')) {
        this.prepareAndDisplayQuote()
      }
    },
    async prepareAndDisplayQuote() {
      this.$emit('loading')
      const canvasTarget = this.$refs.scriptContainer.getElementsByTagName('main')[0]
      this.canvas = await html2canvas(canvasTarget, { useCORS: true })
      this.offerDataUrl = this.canvas.toDataURL()
      this.dialog = true
      this.$emit('page-backable', true)
      this.$emit('loaded')
    },
    sendOffer() {
      this.formLoading = true
      this.canvas.toBlob(async(blob) => {
        const formData = new FormData()
        formData.set('name', this.form.name)
        formData.set('email', this.form.email)
        formData.set('subscribe', this.form.subscribe)
        formData.append('image', blob, 'offer.png')
        await axios({
          method: 'post',
          url: 'https://europe-west2-mammutos-7ad07.cloudfunctions.net/sendmail',
          data: formData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
        this.formLoading = false
        this.successDialog = true
      }, 'image/png')
    },
    back() {
      this.dialog = false
      this.successDialog = false
      this.$emit('page-backable', false)
    },
    acceptKeyboard(text) {
      this.hideKeyboard()
    },
    showKeyboard(e) {
      this.input = e.target
      if (!this.visible) {
        this.visible = true
      }
    },
    hideKeyboard() {
      this.visible = false
    }
  }
}
</script>

<style lang="sass">
  .script-outer-container
    position: relative
    .script-container
      #digeo-car-configurator
        .summary__send
          button.btn.box__btn
            background-color: red
    &.dialog-show
      #digeo-car-configurator
        display: none
    .offer-container
      position: absolute
      top: 0
      left: 0
      width: 100%
      background: white
      z-index: 1

      $fourK: "only screen and (min-width: 2150px) and (min-height: 3830px)"
      @media #{$fourK}
        .v-input
          font-size: 1.6rem
          input
            max-height: 5rem
          ::placeholder
            font-size: 1.6rem
          .v-label
            height: 1.6rem
            line-height: 1.6rem
        .v-label
          font-size: 1.4rem
        .v-text-field
          padding-top: 1rem
          margin-top: 0.3rem
          .v-label--active
            transform: translateY(-1rem) scale(0.75)
          input
            padding: 1rem 0.5rem
        .v-text-field--outlined fieldset
          padding-left: 1rem
        .v-messages
          font-size: 1rem
        .v-input__slot
          min-height: 3rem
        .v-input__prepend-outer
          margin-right: 1.5rem
          margin-bottom: 1rem
          margin-top: 1rem
          .v-input__icon
            height: 2rem
            width: 2rem
        .v-input__icon.v-input__icon--prepend .v-icon.v-icon
          font-size: 2rem
        .v-btn:not(.v-btn--round).v-size--default
          height: 3rem
          padding: 0 1rem
        // checkbox
        .v-input--selection-controls
          margin-top: 1rem
          padding-top: 0.3rem
        .v-input--selection-controls__input
          transform: scale(2)
          margin-right: 2rem
          margin-left: 0.7rem
        .v-card__actions
          padding: 1rem
        .v-btn
          border-radius: 0.3rem
</style>
